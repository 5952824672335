import { Autocomplete, Button, Checkbox, FormControl, Grid, IconButton, InputLabel, ListItemText, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import * as React from 'react';
import * as Redux from 'react-redux';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import axios from 'axios';
import dayjs from 'dayjs';
import { Formik } from 'formik';
import * as yup from 'yup';
import LoadingComponent from '../../../components/Loading';
import { useNotification } from '../../../helpers/notification';
import BotonNuevoComponent from '../../entidades/components/BotonNuevo';
import RSelect from 'react-select/creatable'
import { Remove } from '@mui/icons-material';
const DynamicSelect = () => {
  // Estado para las opciones dinámicas
  const [options, setOptions] = React.useState([
    { value: 'option1', label: 'Opción 1' },
    { value: 'option2', label: 'Opción 2' },
    { value: 'option3', label: 'Opción 3' },
  ]);

  // const defaultOptions = 

  // const selectoptions = [];

  // Estado para los valores seleccionados
  const [selectedOptions, setSelectedOptions] = React.useState([]);

  // Manejar el cambio en el select
  const handleChange = (event) => {
    setSelectedOptions(event.target.value);
  };

  return (
    <FormControl sx={{ width: "300px" }}>
      <InputLabel id="dynamic-multiple-select-label">Seleccionar Opciones</InputLabel>
      <Select
        labelId="dynamic-multiple-select-label"
        id="dynamic-multiple-select"
        multiple
        value={selectedOptions}
        onChange={handleChange}
        renderValue={(selected) => selected.join(', ')}
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            <Checkbox checked={selectedOptions.indexOf(option.value) > -1} />
            <ListItemText primary={option.label} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
const FormAreaExtra = ({ setOpenModalForm, onSuccess = () => {}, extra }) => {
  const { addNotification } = useNotification();
  const [loading, setLoading] = React.useState(false);
  const loginStore = Redux.useSelector((state) => state.login);
  const entidadesStore = Redux.useSelector((state) => state.entidades);

  const timezone = entidadesStore.activo.country.timezone;
  const utcOffset = entidadesStore.activo.country.utcOffset || 'UTC-04:00';

  const onSubmit = async (values) => {
    onSuccess(values);
  };

  const validationSchema = yup.object().shape({
    name: yup.string().required('El nombre es requerido'),
    description: yup.string().optional(),
    price: yup.number().required('El precio es requerido'),
    stock: yup.number().required('El stock es requerido'),
    maxPerReservation: yup.number().required('El máximo por reserva es requerido'),
    options: yup.array().of(yup.object().shape({
      name: yup.string().required('El nombre de la opción es requerido'),
      options: yup.array().of(yup.string()).optional(),
    })).optional(),
  });

  const initialOptions = {
    name: '',
    options: [],
  }

  const initialValue = {
    id: extra?.id || null,
    name: extra?.name || '',
    description: extra?.description || '',
    price: extra?.price || 0,
    stock: extra?.stock || 0,
    maxPerReservation: extra?.maxPerReservation || 0,
    options: extra?.options || [],
  };

  return (
    <Box component="form" sx={{}}>
      <Formik
        initialValues={initialValue}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {({ values, setFieldValue, handleSubmit, errors, validateField, handleChange }) => (
          <Grid container spacing={2}>
            
            <Grid item xs={12} md={12}>
              <TextField
                fullWidth
                size="small"
                id="name"
                required
                label="Nombre"
                value={values.name}
                onChange={handleChange('name')}
                helperText={errors.name}
                error={!!errors.name}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <TextField
                fullWidth
                size="small"
                id="description"
                label="Descripción"
                value={values.description}
                onChange={handleChange('description')}
                helperText={errors.description}
                error={!!errors.description}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                size="small"
                type="number"
                value={values.price}
                onChange={handleChange('price')}
                id="price"
                label="Precio"
                required
                InputProps={{
                  inputProps: { min: 0 },
                }}
                helperText={errors.price}
                error={!!errors.price}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                size="small"
                type="number"
                value={values.stock}
                onChange={handleChange('stock')}
                id="stock"
                label="Stock"
                required
                InputProps={{
                  inputProps: { min: 0 },
                }}
                helperText={errors.stock}
                error={!!errors.stock}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                size="small"
                type="number"
                value={values.maxPerReservation}
                onChange={handleChange('maxPerReservation')}
                id="maxPerReservation"
                label="Máximo por reserva"
                required
                InputProps={{
                  inputProps: { min: 0 },
                }}
                helperText={errors.maxPerReservation}
                error={!!errors.maxPerReservation}
              />
            </Grid>
            
            {/* <Grid item xs={12} md={12}>
              <Button 
                onClick={() => {
                  const options = [...values.options];
                  options.push(initialOptions);
                  setFieldValue('options', options);
                }} 
                variant="contained">
                <Typography variant="body">Agregar opción</Typography>
              </Button>
              <TableContainer component={Paper} style={{ overflow: 'visible' }}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table" style={{ minWidth: '100%' }}>
                  <TableHead>
                    <TableRow>
                      <TableCell>Nombre</TableCell>
                      <TableCell>Opciones</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {
                      values.options.map((option, index) => {
                          if (option.deleted) return null;
                          return (
                            <TableRow key={index}>
                              <TableCell>
                                <TextField
                                  fullWidth
                                  size='small'
                                  required
                                  value={option.name}
                                  helperText={errors?.options?.[index]?.name}
                                  error={!!errors?.options?.[index]?.name}
                                  onChange={(e) => {
                                    const options = [...values.options];
                                    options[index].name = e.target.value;
                                    setFieldValue('options', options);
                                  }}
                                />
                              </TableCell>
                              <TableCell align="right">
                                <RSelect 
                                  options={[]} 
                                  isMulti={true} 
                                  isClearable={false}
                                  getOptionLabel={(option) => option?.value || option}
                                  value={option.options} 
                                  onChange={(e, { action, removedValue, option }) => {
                                    const options = [...values.options];
                                    console.log(e, action, removedValue, option);
                                    if (action === 'remove-value') {
                                      options[index].options = options[index].options.filter(o => o !== removedValue);
                                      console.log(options[index].options);
                                    } else {
                                      if (options[index].options.includes(option.value)) return;
                                      options[index].options.push(option.value);
                                    }
                                    console.log(options);
                                    setFieldValue('options', options);
                                  }}
                                />
                              </TableCell>
                              <TableCell align="right">
                                <IconButton
                                  onClick={() => {
                                    const options = [...values.options];
                                    options.splice(index, 1);
                                    setFieldValue('options', options);
                                  }}>
                                  <Remove />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          )
                        })
                    }
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid> */}
            <Grid item md={12} align="center" sx={{ m: 2 }}>
              <BotonNuevoComponent
                click={handleSubmit}
                text="GUARDAR"
                morado
              />
            </Grid>
            {/* <pre>{JSON.stringify(errors, null, 2)}</pre> */}
          </Grid>
        )}
      </Formik>
    </Box>
  );
};

export default FormAreaExtra;
