import * as React from 'react';
import { Button, IconButton, List, ListItem, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { acceptOnlyImages } from './ButtonFile';
import AddIcon from '@mui/icons-material/Add';


const max = 10;
const ButtonFileMultipleImages = ({
  images = [],
  onChange,
  onDelete,
  accept = acceptOnlyImages,
}) => {


  const [previewImages, setPreviewImages] = React.useState(images);

  React.useEffect(() => {
    setPreviewImages(images);
  }, [images]);

  const previewAndUpload = (files) => {
    const allFiles = [];
    for (let i = 0; i < files.length; i++) {
      let file = files[i];
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        allFiles.push(reader.result);
        if (allFiles.length === files.length) {
          const data = [...previewImages, ...allFiles];
          data.splice(max);
          setPreviewImages(data);
        }
      };
    }
    onChange(files);
  }

  const handleDeleteImage = (index) => {
    const newPreviewImages = [...previewImages];
    const element = newPreviewImages.splice(index, 1);
    setPreviewImages(newPreviewImages);
    if (element[0].includes('http')) {
      onDelete(element[0]);
    }
  }
  return (
    <div style={{ width: '100%' }}>
      <Typography variant="h6" gutterBottom component="div" align="center">
        Imágenes
        
        { previewImages.length < max && (
          <label htmlFor="contained-button-file">
            <input
              id="contained-button-file"
              name="contained-button-file"
              style={{ display: 'none' }}
              type="file"
              accept={accept}
              multiple
              onChange={(e) => previewAndUpload(e.target.files)}
            />
            <Button component="span" size="small">
              <AddIcon fontSize="small" />
            </Button>
          </label>
        )}
      </Typography>
      <List style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
        {
          previewImages.map((image, index) => (
            <ListItem key={index} style={{ width: '100px', height: '100px', position: 'relative' }}>
              <IconButton
                aria-label="delete"
                onClick={() => handleDeleteImage(index)}
                style={{ position: 'absolute', zIndex: 1, top: 0, right: 0 }}
                color="error">
                <DeleteIcon style={{ width: '15px', height: '15px' }} />
              </IconButton>
              <img
                style={{
                  maxWidth: '100%',
                  maxHeight: '100%',
                }}
                src={image}
                alt={image}
              />
            </ListItem>
          ))
        }
      </List>
    </div>
  );
}

export default ButtonFileMultipleImages;