import { Box, Card, CardContent, CardHeader, Chip, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import React from "react";
import { getAllReservasService, getOneAreasService } from "../services";
import * as Redux from 'react-redux';
import { getDateTimezone } from "../helpers";
import dayjs from "dayjs";
import LoadingComponent from "./Loading";
import {
  DatePicker,
  LocalizationProvider,
  PickersDay,
} from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import UserIcon from '@mui/icons-material/Person';

const buildDate = (date, time) => {
  const [h, m, s] = time.split(':');
  return dayjs(date)
    .set('hour', h)
    .set('minute', m)
    .set('second', s)
    .set('millisecond', 0)
    .toDate();
};

export default function ReservationUsedBlocks({
  areaIds = [],
  dates = [],
  refresh = false
}) {

  const entidadesStore = Redux.useSelector((state) => state.entidades);
  const [loading, setLoading] = React.useState(false);
  const [date, setDate] = React.useState(dates[0] || null);

  const [blocks, setBlocks] = React.useState([]);
  const [areaId, setAreaId] = React.useState(null);

  const selectedBlock = blocks.find((el) => el.id === areaId);
  const {timezone, utcOffset} = entidadesStore.activo.country;
  // const utcOffset = entidadesStore.activo.country.utcOffset || 'UTC-04:00';

  

  const buildBlockReservation = (startTime, endTime, date, interval, reservations) => {
    const startDate = getDateTimezone(dayjs(buildDate(date, startTime)).format('YYYY-MM-DD HH:mm:ss'), timezone);
    const endDate = getDateTimezone(dayjs(buildDate(date, endTime)).format('YYYY-MM-DD HH:mm:ss'), timezone);
    const data = [];
    let currentDate = startDate;
    let nextDate = dayjs(currentDate).add(interval, 'minute').toDate();
    let i = 0;
    if (interval == 1440) {
      nextDate = dayjs(currentDate).add(1439, 'minute').toDate();
    }
    while (currentDate < endDate) {

      const reservation = reservations.find((el) => {
        return el.entryTime.getTime() === currentDate.getTime() && el.departureTime.getTime() === nextDate.getTime();
      });

      data.push({
        id: i,
        label: `${dayjs(currentDate).utcOffset(utcOffset).format('hh:mm A')}`,
        departureTime: nextDate,
        entryTime: currentDate,
        reservation,
      });
      currentDate = nextDate;
      nextDate = dayjs(currentDate).add(interval, 'minute').toDate();
      i++;
    }
    return data;
  };

  const getData = async (params = {}) => {
    try {
      setLoading(true);
      const areaPromises = areaIds.map((areaId) => {
        return getOneAreasService(areaId, {
          detailSubareas: true
        });
      });
      const areaRes = await Promise.all(areaPromises);
      const areas = areaRes.map((el) => el.data).flat();
      const reservationPromises = areas.map((area) => {
        return getAllReservasService({
          areaId: area.id,
          entityId: entidadesStore.activo.id,
          date: new Date(date),
        });
      });
      const reservationRes = await Promise.all(reservationPromises);
      const reservations = reservationRes.map((el) => el.data.data).flat().map((el) => {
        return {
          ...el,
          entryTime: new Date(el.entryTime),
          departureTime: new Date(el.departureTime),
        };
      });
      const blocks = [];
      const dateDayIndex = dayjs(date).day();
      for (const area of areas) {
        const { blockReservation } = area;
        const blockData = {
          name: area.name,
          id: area.id,
          subareas: [],
        }
        for (const subarea of area.subareas) {
          const { dates, interval } = subarea;
          const subareaDate = dates.find((el) => el.day === dateDayIndex);
          const { startTime, endTime } = subareaDate;
          const subareaReservations = reservations.filter((el) => el.subarea.id === subarea.id);

          const subareaData = {
            name: subarea.name,
            blocks: buildBlockReservation(startTime, endTime, date, interval, subareaReservations),
          }
          blockData.subareas.push(subareaData);
        }
        blocks.push(blockData);
      }
      setBlocks(blocks);
    } catch (error) {
      setBlocks([]);
    } finally {
      setLoading(false);
    }
  }

  React.useEffect(() => {
    if (areaIds.length > 0 && dates.length > 0) {
      getData();
    } else {
      setBlocks([]);
    }
    if (areaIds.length > 0) {
      setAreaId(areaIds[0]);
    }
  }, [areaIds, date]);

  React.useEffect(() => {
    console.log('refresh', refresh);
    if (refresh) {
      getData();
    }
  }, [refresh]);

  React.useEffect(() => {
    if (dates.length > 0) {
      setDate(dates[0]);
    }
  }, [dates]);

  return (
    <Grid item xs={12} md={12} sx={{ paddingTop: 2 }}>
      
      <LoadingComponent
        isLoading={loading}
        text="..."
      />
      <Grid item xs={12} md={12} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>

        {
          areaIds.length > 1 && (
            <FormControl>
              <InputLabel id="area">Área</InputLabel>
              <Select
                size="small"
                value={areaId}
                label="Área"
                onChange={(e) => setAreaId(e.target.value)}
              >
                {
                  blocks.map((block) => (
                    <MenuItem key={block.id} value={block.id}>{block.name}</MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          )
        }
        {
          dates.length > 1 && (
            <FormControl>
              <LocalizationProvider
                adapterLocale={'es'}
                dateAdapter={AdapterDayjs}
              >
                <DatePicker
                  label={'Fecha'}
                  value={date}
                  openTo="day"
                  size="small"
                  adapterLocale={'ve'}
                  shouldDisableDate={(date) => !dates.find((el) => dayjs(el).isSame(date, 'day'))}
                  onChange={(date) => {
                    setDate(date);
                  }}
                  views={['day']}
                  renderInput={(params) => (
                    <TextField
                      fullWidth
                      size="small"
                      required
                      {...params}
                    />
                  )}
                />
              </LocalizationProvider>
            </FormControl>
          )
        }
      </Grid>
      {
        selectedBlock ? (
          <Box sx={{ maxWidth: 1200, margin: 'auto', padding: 2 }}>
            {/* <Typography variant="h3" component="h1" gutterBottom>
              Reservas de {selectedBlock?.name}
            </Typography> */}
            <Grid container spacing={3}>
              {selectedBlock.subareas.map((subarea) => (
                <Grid item xs={12} md={12} lg={6} key={subarea.name}>
                  <Card>
                    <CardHeader title={subarea.name} />
                    <CardContent>
                      <Grid container spacing={1}>
                        {subarea.blocks.map((block, idx) => {
                          const isAvailable = !block.reservation;
                          let isShared = false;
    
                          if (block.reservation && block.reservation.userCode && block.reservation.subarea.maxPersonsShared > 1 && block.reservation.subarea.maxPersonsShared - block.reservation.personsShared > 0) {
                            isShared = true;
                          }

                          return (
                              <Chip
                                label={
                                  <Box>
                                    <Typography variant="caption" display="block">
                                      {block.label}
                                    </Typography>
                                    {
                                      block.reservation && (
                                        <div className="reservation">
                                          {
                                            block.reservation.description && (
                                              <div className="description">{block.reservation.description}</div>
                                            )
                                          }
                                          {
                                            block.reservation.userCode && (
                                              <>
                                                {
                                                  block.reservation.subarea.maxPersonsShared > 1 ? (
                                                    <>
                                                      {
                                                        block.reservation.subarea.maxPersonsShared - block.reservation.personsShared > 0 ? (
                                                          <div className="user" style={{ display: 'flex', alignItems: 'center' }}>
                                                            <UserIcon fontSize="small" sx={{ mr: '2px'}} />
                                                            Disponible: {block.reservation.subarea.maxPersonsShared - block.reservation.personsShared}
                                                          </div>
                                                        ) : (
                                                          <div className="user" style={{ display: 'flex', alignItems: 'center' }}>
                                                            <UserIcon fontSize="small" sx={{ mr: '2px'}} />
                                                            Agotado
                                                          </div>
                                                        )
                                                      }
                                                    </>
                                                  ) : (
                                                    <div className="user" style={{ display: 'flex', alignItems: 'center' }}>
                                                      <UserIcon fontSize="small" sx={{ mr: '2px'}} />
                                                      {block.reservation.userCode.code.code}
                                                    </div>
                                                  )
                                                }
                                              </>
                                            )
                                          }
                                        </div>
                                      )
                                    }
                                    {/* {reservation.info && (
                                      <Typography variant="caption" display="block">
                                        {reservation.info}
                                      </Typography>
                                    )} */}
                                  </Box>
                                }
                                color={ isShared ? 'secondary' : isAvailable ? 'primary' : 'error'}
                                sx={{
                                  width: '130px',
                                  height: '55px',
                                  textAlign: 'center',
                                  margin: '4px',
                                  '& .MuiChip-label': {
                                    display: 'block',
                                    whiteSpace: 'normal',
                                    padding: '8px 0',
                                  },
                                }}
                              />
                            // <Grid item xs={4} sm={3} key={idx}>
                            // </Grid>
                          )
                        })}
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Box>
        ) : (
          <Typography variant="h6" sx={{ textAlign: 'center', padding: 2 }}>
            No hay bloques disponibles
          </Typography>
        )
      }
    </Grid>
  )
}